$columns: 12;
$gap: 15px;
$breakpoints: (
  xs: 480px,
  sm: 768px,
  md: 960px,
  lg: 1170px,
  xl: 1280px
);

@mixin create-selectors($breakpoint: null) {
  $infix: if($breakpoint == null, '', '-#{$breakpoint}');

  @for $i from 1 through $columns {
    .col#{$infix}-#{$i} {
      grid-column-end: span $i;
    }
    .col-offset#{$infix}-#{$i} {
      grid-column-start: $i + 1;
    }
    .row#{$infix}-#{$i} {
      grid-row-end: span $i;
    }
    .row-offset#{$infix}-#{$i} {
      grid-row-start: $i + 1;
    }
  }
}

.grid {
  padding-inline: $gap;
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-gap: $gap;
  gap: $gap;
}

@include create-selectors;

@each $breakpoint, $width in $breakpoints {
    @media (min-width: $width) {
        @include create-selectors($breakpoint);
    }
}

/* Library custom */

/* Library custom */
.ptr--ptr {
  background-color: #000; // Set the background color to black
  color: #fff; // Set the text color to white
}

.ptr--content {
  font-size: 13px;
}

.ptr--text {
  color: #fff !important; // Force the text color to be white
}

.ptr--icon,
.ptr--icon::before {
  color: #fff !important; /* Make sure the icons are white */
}

.ptr--icon svg {
  fill: #fff !important; /* If using SVGs, set the fill color */
}

/* App code */

body {
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.App {
  text-align: center;
  height: 100vh;
}

.back-button, .close-button {
  z-index: 999;
  user-select: none;
  border-width: 0;
  padding: 0;
  background-color: white;
  box-shadow: 1px 2px 5px 3px rgba(0,0,0,0.1);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 10px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-left: -3px;
    width: 30px;
    height: 30px;
  }
}

.back-button {
  left: 10px;
}

.close-button {
  right: 10px;
}

.home-page {
  .reel-item {
    padding: 15px 0 0;

    &--empty {
      border-radius: 15px;
      background-color: #000;
      width: 100%;
      height: 100dvh;
      margin-top: 15px;
    }

    .reel-view-button {
      padding-top: 10px;
    }
  }
}

.logo-wrapper {
  background: #000;
  margin-bottom: -40px;
}

.logo {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.navigation {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;
}

.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
  font-size: 14px;

  &::before {
    content: '';
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: 20px;
  }

  &--no-before {
    &::before {
     height: 15px;
    }
  }
}

/* Profile page */

.profile-photo-wrapper {
  background: #000;
  margin-bottom: 10px;
}

.profile-controls {
  position: fixed;
  top: 15px;
  right: 10px;
  display: flex;

  button:first-of-type {
    margin-right: 5px;
  }
}

.profile-photo-container {
  width: 100%;  // Set the desired width
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f0f0f0; // Background color to avoid flash
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.profile-photo {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  &--empty {
    height: 520px; // Set the desired height
  }
}


/* Reel view page */

.reel {
  position: relative;
  background-color: #000;
}

/* Create reel page */

.create-reel {
  padding-top: 80px;
  padding-bottom: 10px;
}

/* Edit profile page */

.edit-profile {
  padding-top: 80px;
  padding-bottom: 10px;

  .profile-image {
    margin-bottom: 10px;
  }
}

/* Auth page */

.auth-page {
  padding-top: 40px;

  .consent-checkbox {
    margin-right: 10px;
  }

  .auth-actions {
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;
  }
}

/* ReelTile component */

.reel-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  aspect-ratio: 4 / 5;
  cursor: pointer;
  border-radius: 15px; // Add border radius
  background: #000;

  .thumbnail {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 125%; // 4:5 aspect ratio
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
      object-fit: cover; // Ensures image covers the area while maintaining aspect ratio
    }
  }

  .reel-info {
    padding: 8px;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    color: #fff;

    h4 {
      margin: 0;
      font-size: 1rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      align-self: start;
      justify-self: start;
    }

    button {
      padding: 4px 8px;
      background: transparent;
      border: none;
    }
  }

  .creator-info {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    min-height: 30px;

    .profile-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #fff;
      box-shadow: 1px 2px 5px 3px rgba(0,0,0,0.1);
    }

    .username {
      color: #fff;
      font-size: 0.9rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    }
  }
}

/* ProfileButton */

.profile-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  background-color: #000; // Background color for the button
  box-shadow: 1px 2px 5px 3px rgba(0,0,0,0.1);

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-placeholder {
    width: 100%;
    height: 100%;
    background-color: #333; // Placeholder color
  }
}

/* CreateReelButton */

.create-reel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 24px;
  border: none;
  cursor: pointer;
  user-select: none;
  border-width: 0;
  padding: 0;
  box-shadow: 1px 2px 5px 3px rgba(0,0,0,0.1);

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

/* Pill Button */

.pill-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  white-space: nowrap; // Prevent text from wrapping
  box-shadow: 1px 2px 5px 3px rgba(0,0,0,0.1);

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
  }
}